import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import styles from "./locations.module.scss";
import Hero from "./Hero";
import Amenities from "./Amenities";
import Managers from "./Managers";
import Membership from "../../components/Widgets/Membership";
import Schedule from "../../components/shared/Schedule";
import Helmet from "../../components/Helmet";
import GradientImageBanner from "../../components/Widgets/GradientImageBanner";
import useWindowWidth, {
    heroDesktopMinWidth,
} from "../../hooks/useWindowWidth";
import WarningComponent from "../../components/shared/FallbackWarnComponent";
import api from "../../services/DashboardApiService"
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";
import { Link } from "gatsby";
interface PageProps {
    pageContext: any;
}

const LocationsTemplate = (
    props: PropsWithChildren<PageProps>
): ReactElement => {
    const isDesktop = useWindowWidth(heroDesktopMinWidth);
    const { data, relations } = props.pageContext;
    const { try_us } = relations;
    const [memberships, setMemberships] = useState(null as any)
    const [classes, setClasses] = useState(null as any)
    const google_maps_url = data?.google_maps_url?.url;
    
    const bannerProps = {
        banner_title: try_us.dataRaw.title,
        banner_text: try_us.dataRaw.subtitle,
        banner_background: try_us.dataRaw.background_image,
        banner_cta_text: try_us.dataRaw.cta_text,
        banner_cta_url: data.flyer_image.url ? {slug: "contact-us"} : try_us.dataRaw.cta_url,
        banner_gradient_color: try_us.dataRaw.grandient_color,
        banner_text_color: try_us.dataRaw.text_color,
    };

    const background = isDesktop
        ? data.location_img.url
        : data.mobile_background?.url || data.location_img.url;

    const backgroundAlt = isDesktop
        ? data.location_img.alt
        : data.mobile_background?.alt || data.location_img.alt;

    useEffect(() => {
        if(!props.pageContext.data.location) return;
        const responseApi = async () => {
            const apiData = await api.get(`agreements/${props.pageContext.data.location.businessUnitCode}`);
            setMemberships(apiData.data.response?.agreements)
        }

        const responseClassesApi = async () => {
            const apiData = await api.get(`club/${props.pageContext.data.location.businessUnitCode}/classes`);
            setClasses(apiData.data)
        }
        responseClassesApi();
        responseApi();
    }, [])

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Location")}
                description={getSeoDescription(data, "Location page")}
                keywords={getSeoKeywords(data, "")} 
            />
            {!data.flyer_image.url &&
            <Hero
                title={data.page_title[0].text}
                subtitle={data.page_subtitle[0]?.text}
                backgroundImage={background}
                backgroundAlt={backgroundAlt}
                address={data.club_address}
                phone={data.phone}
                hoursOpen={data?.club_hours}
                club={props.pageContext.data.location.businessUnitCode}
                googleMapsUrl={google_maps_url}
            />
            }

            {data.flyer_image.url &&
            <div className={styles.flyerWrapper}>
                <Link to={data.join_us_link.url} >
                    <img src={data.flyer_image.url}/>
                </Link>
            </div>
            }

            {!data.flyer_image.url && relations.amenities && relations.amenities.length > 0 ? (
                <div className={`${styles.wrapperAmenities} mainBox`}>
                    <h2 className={styles.title}>Amenities</h2>
                    <div className={styles.amenities}>
                        {relations.amenities.map((amenity: any, i: number) => {
                            return <Amenities {...amenity} key={i} />;
                        })}
                    </div>
                </div>
            ) : (
                <WarningComponent
                    template={`Locations ${data.page_title[0]?.text}`}
                    message={"Amenities"}
                />
            )}


            {memberships != null  && <Membership membershipData={memberships} selectedLocation={props.pageContext.data.location} />}

            {!data.flyer_image.url && <Schedule classes={classes} />}

            {!data.flyer_image.url && relations.managers && relations.managers.length > 0 ? (
                <div className={`${styles.managers} px-2`}>
                    <div className="mainBox">
                        <h2 className={styles.title}>THE MANAGEMENT TEAM</h2>
                        <div className={styles.managersCard}>
                            {relations.managers.map(
                                (manager: any, i: number) => {
                                    return <Managers {...manager} key={i} />;
                                }
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <WarningComponent
                    template={`Locations ${data.page_title[0]?.text}`}
                    message={"Managers"}
                />
            )}

            {try_us.dataRaw ? (
                <GradientImageBanner data={{ ...bannerProps }} />
            ) : (
                <WarningComponent
                    template={`Locations ${data.page_title[0]?.text}`}
                    message={"Try us widget"}
                />
            )}
        </>
    );
};

export default LocationsTemplate;
