import { Link } from "gatsby";
import React, { ReactElement, useEffect, useRef } from "react";
import { arrows, chevron, errorFilterIcon } from "../../../shared/icons";
import Card from "./Card";
import styles from "./Schedule.module.scss";
import { IMosoClasses } from "./ScheduleInterfaces";


enum NavDirection {
    Right = 1,
    Left = -1,
}

const cardSize = 285;
const cardSeparation = 15;

const Schedule = (_props: any): ReactElement => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const scroll = (value: number) => {
        scrollRef.current?.scrollTo({ left: value })
    }

    const handleArrowNavigation = (direction: number): void => {
        direction === NavDirection.Right
            ? scroll(scrollRef.current!.scrollLeft += (cardSize + cardSeparation))
            : scroll(scrollRef.current!.scrollLeft -= (cardSize + cardSeparation));
    };

    const handleOnWheel = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();

        const target = e.currentTarget as HTMLDivElement;
        const { scrollLeft, firstChild } = target;
        const delta = Math.sign(e.wheelDelta);
        scroll(scrollLeft + (((firstChild as any).clientWidth + cardSeparation) * -delta));
    }

    useEffect(() => {
        if (!scrollRef?.current) return;

        scrollRef.current.addEventListener("wheel", handleOnWheel, { passive: false });
        return () => scrollRef.current?.removeEventListener("wheel", handleOnWheel);
    }, []);

    let nextClasses: Array<IMosoClasses> = []

    //order classes
    _props.classes?.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    //check for classes > this moment
    _props.classes?.forEach((elementClass: IMosoClasses) => {
        if (new Date(elementClass.startDate).getTime() > new Date().getTime()) nextClasses.push(elementClass);
    })
    return (
        <section
            className={`${styles.wrapper} mainBox`}
            style={{
                ["--cardSize" as string]: `${cardSize}px`,
                ["--cardSeparation" as string]: `${cardSeparation}px`
            }}
        >
            <h3 className={styles.title}>Upcoming classes</h3>

            {_props?.classes && nextClasses.length > 0 ? <div className={styles.cardsWrapper}>
                <button
                    className={styles.left}
                    onClick={() => handleArrowNavigation(NavDirection.Left)}
                >
                    {chevron.left}
                </button>


                <div ref={scrollRef} className={styles.scrollContainer}>
                    {nextClasses.map((_class: object, i: number) => (
                        <Card {..._class} key={i} />
                    ))}
                </div>

                <button
                    className={styles.right}
                    onClick={() => handleArrowNavigation(NavDirection.Right)}
                >
                    {chevron.right}
                </button>
            </div> : <div className={styles.noClassesAlert}>
                <p className={styles.alertText}>There are no upcoming classes, please try again later!</p>

            </div>}

            <button className={styles.cta}>
                <Link to={"/scheduler"} className={styles.resetLink}>
                    <span>View all times</span>
                    {arrows.right}
                </Link>
            </button>


        </section>
    );
};

export default Schedule;
